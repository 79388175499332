import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import MineralswimBanner from '../../images/products/MineralSwimV2_BENEFITS_100_DeadSea_1920x1000.jpg'
import Holder from '../../images/image.png'
import { Link } from 'gatsby'
import BulletLogo from '../../components/svg/BulletLogo'
const DeadSeaSalt = () => (
	<Layout>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Unlocking the Benefits of Dead Sea Salt for Your Pool | MineralSwim</title>
			<meta
				name="description"
				content="constituent of dead sea salt, benefits of dead sea salt to skin and wellbeing."
			/>
			<meta
				name="keywords"
				content="dead sea, deadsea, dead sea salt, magnesium pools, Sea Minerals, bathing in sea salt, dead sea salt benefits, Dead Sea mineral pool"
			/>
			<link rel="canonical" href="https://mineralswim.com/benefits/dead-sea-salt/" />
		</Helmet>
		<main  className="bg-white relative">
			<header className='w-screen absolute main-page-header bg-center z-10'
						>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'>						
							<div className='hidden lg:flex col-span-6'></div>
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
								<div className='bg-white bg-opacity-40 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Dead Sea Minerals</h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>	
									</div>
									<p className='text-black md:text-base leading-6'>Discover the therapeutic, health and well-being benefits of adding natural magnesium-rich Dead Sea Minerals to your swimming pool.
										</p>					
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
										<Link to="/s/?page=benefits&article=dead-sea-salt">             
									<p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
									</Link>			
									</div>	
									</div>								
									</div>                
							</div>
								</header>
				<div className='top-0 bg-cover bg-right lg:bg-right z-0' style={{
                backgroundImage: `url(${MineralswimBanner})`,
								backgroundAttachment: 'fixed',
								position: 'relative',
								height: '90vh',
            }}
						data-scroll
      data-scroll-speed="-10"
						>
							
								</div>
								<div className='relative -mt-24 md:-mt-24 lg:-mt-40 pt-40 pb-20 bg-white z-10'>
		<section  id="main-content" className="max-w-screen-xl font-display text-base mx-auto mb-20 text-justify md:text-left px-8 md:px-10 grid grid-cols-1 md:grid-cols-2 gap-10">
						
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Unique Hydration Benefits of Dead Sea Salt </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Salt might not be the first thing that comes to mind when you think of hydration, but did you know that the unique mineral composition of Dead Sea salt can help moisturise and replenish your skin?  </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/53373620886uniquehydrationbenefits_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=eRRs8xDSLh8G4MkvpWFSFjVbF8HlSKhodBopD73ra4NGdTVVZh7hUavAfD8pcJKTUdIStvqmVoSPe1LJgCs3IjqZ0qg4MXZKo%2F9jkiAbXpyDT7CV9mkMe3ovHl78w7cAR%2FlAfSkKt%2FyAgl5Q7gkcg1q8g8QFwx2YTt1OkyuJb9kJSnDHdWNDr7%2BEbKaPRLpecX2Bpbo2aYcy6Oad7HT%2Bos0mstEumRKGQs9VNvY08ErHDoGkw1nV5ql6UZ%2Fxe95p%2BR02W5iLdNbUW%2BHrQ%2BFZnFBzkdpm6OSm6In1%2F5eq%2Bect%2FXV2WcT5e7CZu%2FP3aBx71fWmo%2BUKFLF2K6egu8jHjw%3D%3D" alt='Unique Hydration' className='w-full h-72 border object-cover rounded-2xl'/>	
							
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>The Therapeutic History of the Dead Sea </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>For thousands of years, the hyper-saline Dead Sea has attracted visitors seeking its therapeutic and healing properties for skin conditions and relaxation. Dead Sea salt contains ten times more minerals than regular sea salt and is proven to cleanse, detoxify, and restore health, particularly to the skin and muscles.</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/92024458651thetherapeutichistory_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=G6WPS657wXW6ABwN%2FccjMLUcez0c3oQ8DaJ4VoYnco1P75wSwjSllgJzJEvuvLIp4zBahiYHfOKedN6X7GJjZbEk1iM5xFI2G8ZjiFISsVV%2F3ovTY3hW9kS3Cp67uu6gdCAaMqXoV%2BQgz%2FITU5IDdYOH1PvFujd0lbictPEqsTzLA9EZ7CCrFNX8Nq7i9svZi9QzFtj1it%2Ff1ppS0gHWNYZ74awRY%2FKyDkq6QbL8aXwUDW3OLxjf6hmDv62IZbJ%2FiZRlbC%2Fs%2B0hNywoe620R4kj5qsbnxnBG57C4Aas9M%2FHES5FkluaM1sB7REtrWT3pryijWh%2Bj2oiqwprJmhS7qQ%3D%3D" alt='Therapeutic History' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Bringing the Dead Sea to Your Home </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>While visiting the Dead Sea would be a phenomenal experience, you don’t need to travel across the globe to enjoy its therapeutic wonders. A magnesium pool can offer the same benefits right at home! </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/55808005656bringingthedeadsea_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=LfZ0UjIM%2FF1FLA0MgI83lVuoRtu3QeSV8tSPb7%2FIFkovUiDDuvCuV307Rixb21V9h1hvD1mvi1YqiadFKiOVqsXPujxEOopDg%2BPDdNiewZTzexROup19Fgh1tLHhPHmhrDHYTGdlzyNOrgMIE7239t7DMOXa%2BxsI6IC0cfAUcQWYzl0wAFtH2alE%2FVB0602TYQPxK0PST2Hszf7%2F%2Biz2pZo3nrkm29SQQoVJizdFkAyYFCjH9FbC%2BCvyM8q37yYUbsW19lDKLRZ6J9awhWp1GrEi4MZ%2BGbEtC%2Fgho2NvWPGOkI59TTPliIh%2B3tBiUS4NVE2J2P5ni3bw9ZH7HRY53Q%3D%3D" alt='Bringing the Dead Sea' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>The Science Behind Magnesium Pools </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Magnesium pools, designed exclusively with 100% Dead Sea minerals, combine the science of these salts with a natural purification system to bring therapeutic, healthy, and crystal-clear water to your swimming pool. </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/1299789968thesciencebehind_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=N7cVGT344RGde%2FnzgOufljn5h7bhWJyLljLD%2BHXGGIZJwBjHZo5a7TIdb8fpzPYBqYrYkrCT4tLQ044oZ3VFJeyU8BtZ5cbigqFBtZbC6EbbKKSMdPAp7%2B3rEw0HPQoL9e5DYDTTU4v%2FXYt3IY9PX8tBVnKIb8JuQl%2BvOlbhgJwjeGK8F3kl5NFut1p2jIS7fS5V1KC%2F3DqQ5XOkvRy3XLFh9MEhd4cNsAHhypt6B9EPZxnub%2F6i7Hn3xmOHF7eIsjUuCs%2B2XuW7xiEDBARVd5%2F1bxDd5KxhvJimJGUca0ieG7%2Bm%2FkJX99B7spBynteyF8Jm1IYS5WRg28J1EaT8NA%3D%3D" alt='The Science Behind' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Health Benefits of Magnesium Pools </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<div>
						<p>This blend of Dead Sea salt and natural sanitation offers numerous health benefits, including: </p>
						<ul className='flex flex-col pl-4 sm:pl-8 py-4'>
							<li className='flex'><BulletLogo /> Improved skin texture and elasticity </li>
							<li className='flex'><BulletLogo /> Boosted energy levels  </li>
							<li className='flex'><BulletLogo /> Increases hair softness  </li>
							<li className='flex'><BulletLogo /> Enhanced blood circulation </li>
							<li className='flex'><BulletLogo /> Anti-inflammatory  </li>
							<li className='flex'><BulletLogo /> Muscle relaxation </li>
							<li className='flex'><BulletLogo /> Better sleep </li>
</ul>
</div>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/48819614903healthbenefitsofmagnesium_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=AhTh8k%2BDL4ZR0GXw9ydeVqSdsp2m%2BYioMKiRwfGO4nu0chXA96ST5y45eqoIL0SXnLl2y3%2FyRcUTRh1SNhwkJAdIcVLHpzujMp9u2tUZBlSoy4lDyT58yjj%2BkTx2%2Fo3%2FAZbVRmtrawKVxctwE%2F9M83qNqVMwO01rqnxhvidiaUg8aqFQHkX33Xn0vXWmM9LY9eEVBgc2d9Mpo6wAWrZVsC0CKd80fwoYVoilpe5HqnXov0UOhL5qv8WPijkJ1wVlqcB%2FopD%2FvEUZNFm3NL0JrK8LqwNt%2F26qVnA14qwXTjTZzTfZXNGJ5FhTAoCSh63ANMR67KsDP1x7y%2BJ%2F12lOFA%3D%3D" alt='Health Benefits of Magnesium' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Dermatologist-Approved Healing </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>Bathing in magnesium pools has been scientifically proven to penetrate and repair skin during and after swimming. Dermatologists worldwide recognise magnesium's healing effects on sensitive skin and its ability to soothe common conditions such as dermatitis, eczema, and psoriasis. </p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/76158031440dermatologist_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=VSEZBdrkQHS22EnBvfQhnRy5DcsH%2Bj1jijFFq7oRYq4%2F0trxvqfG1rN%2F%2BiQACQ1qQL%2BUusSrNtMe5aOGMiDHrPl534iDylfRpGlLiZitYOE3DOfTIsRitKqzzpyP4WLx%2BX9GUBlnl4%2Ffzi%2FZdwRX6%2FaMQ0Mhjef5PfLpL7Ccz5RGcwfm1T6kYH1XXEwbOazsEyJWjGrjF4QOZn0ozakucNQLQK1lmJAv3vsC63vGLnub9pAY921lWxL5GQQHnZKl7Cv4N0YZmDMBwFf3P5Ets%2BQHq9VsOKgl1aQrfshiOenm%2B6EPVuySQqEfvhaNIBzykKupBG0FkKh9BXEKQqwFkw%3D%3D" alt='Dermatologist Approved Healing' className='w-full h-72 border object-cover rounded-2xl'/>	
		<div className='h-full w-full flex flex-col justify-center gap-4'>
					<h3 className='text-2xl font-bold text-left'>Unmatched Benefits and Sensory Experience </h3>
					<div>
					<div className='w-20 md:w-16 h-1 mt-4 md:mt-2 border-2 border-blue-500'/>
					</div>

					<p>The health benefits of magnesium pools are unmatched. The flocculant effect of magnesium-rich Dead Sea salts and minerals clarifies the water, ensuring a smooth, frictionless experience as you glide through the pool. It delights the senses, restores the skin, and nourishes the spirit. 
						<br/>
						<br/>
						Harnessing the power of natural Dead Sea minerals offers more than just improved water quality. It also promotes a healthier lifestyle by providing a pool experience that is gentler on the skin, eyes, and hair, in contrast to the harsh effects of salt and chlorine swimming pools. Make the switch and Mineral Swim™ Dead Sea minerals to your swimming pool today.  

					</p>
					</div>
					<img src="https://storage.googleapis.com/sales-audit.appspot.com/61998904209unmatchedbenefits_image.jpg?GoogleAccessId=firebase-adminsdk-b0ru6%40sales-audit.iam.gserviceaccount.com&Expires=4102444800&Signature=FtlyeRUkByib7emidY3%2F2X13qsk%2F63hKrBmZNnkbx48L5sChBu2xrv673HuL2yDeUXuxBV24ldm4b8Mp7E1TJ%2BBYNi25IMCbCcrJuo3xM6GYSQWiEEWRRDtUbv0JPX4vCTarQXAdzgHvbCr7WtAm%2Fjcp3EzeM%2BxH1bCr%2Bp7neKnlAK20rfL0o%2FDp4tWc400O2boHAznttb2tTIwqC1Y7vkGaTyMaiM4OuNpujYXWNFTNioy6wOhU8ITp2pvpt2ydfTiFWElaOnKN1LdRldUS2fPAU3PtPgUJPigewHyuRlQthPSklZnIC7VjtDy25g0UOnxTW%2BKITFBsem1Gc%2BRCeQ%3D%3D" alt='Unmatched Benefits' className='w-full h-72 border object-cover rounded-2xl'/>	
				</section>
				
				<div className="flex flex-col sm:flex-row gap-4 items-center justify-center lg:flex-1 font-display text-base font-semibold uppercase">
					<Link
					to="/benefits"
									className="cursor-pointer flex items-center justify-center  w-48 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-800 bg-gray-100 hover:bg-gray-300 whitespace-nowrap"
								>
									Back to Benefits
								</Link>
						<Link
							to="https://www.maytronics.com.au/product/mineral-swim-minerals"
							className="whitespace-nowrap inline-flex items-center justify-center w-48 py-1 border border-transparent rounded shadow-sm bg-blue-500 hover:bg-blue-400 text-white"
						>
							Buy Now
						</Link>
					</div>
				</div>
				</main>
	</Layout>
)

export default DeadSeaSalt
